import React from "react";
import {
	ChipField,
	Datagrid,
	DateField,
	List,
	ReferenceField,
	TextField,
} from "react-admin";
import ImageField from "../components/ImageField";
import Pagination from "../components/Pagination";
import perPage from "../utils/perPage";
import Filters from "./Filters";

const DrawingList = (props) => (
	<React.Fragment>
		<List
			exporter={false}
			{...props}
			perPage={perPage()}
			filters={<Filters />}
			sort={{ field: "created", order: "DESC" }}
			pagination={<Pagination />}
		>
			<Datagrid rowClick={false}>
				<TextField source="id" />
				<ReferenceField source="order_id" reference="orders">
					<ChipField source="code" />
				</ReferenceField>
				<ImageField source="image.src" title="image.name" label="Image" />
				<DateField showTime source="created" />
			</Datagrid>
		</List>
	</React.Fragment>
);
export default DrawingList;
