import EmailIcon from "@material-ui/icons/Email";
import Create from "./Create";
import Edit from "./Edit";
import List from "./List";

export default {
	options: {
		group: "admin",
		roles: ["admin"],
		label: "Emails",
	},
	icon: EmailIcon,
	list: List,
	edit: Edit,
	create: Create,
};
