import { Chip } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { get } from "lodash";
import moment from "moment";
import React from "react";

const PrintedField = ({ record, source, ...props }) => {
	const printed = get(record, source, "Not printed yet");
	return (
		<Chip
			style={{
				backgroundColor: printed ? green[500] : red[500],
				color: "white",
			}}
			component="p"
			label={printed ? moment(printed).format("l h:mm") : "Not printed yet"}
			icon={printed ? <CheckIcon /> : <ClearIcon />}
		/>
	);
};

export default PrintedField;
