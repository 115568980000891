import React from "react";
import {
	ChipField,
	Datagrid,
	EditButton,
	List,
	NumberField,
	TextField,
} from "react-admin";
import Pagination from "../components/Pagination";
import Filters from "./Filters";

const AttributeList = (props) => (
	<React.Fragment>
		<List
			{...props}
			exporter={false}
			perPage={25}
			filters={<Filters />}
			sort={{ field: "name", order: "ASC" }}
			pagination={<Pagination />}
		>
			<Datagrid rowClick="edit">
				<TextField source="id" />
				<TextField source="name" style={{ fontWeight: "bold" }} />
				<ChipField source="code" />
				<NumberField
					source="cost"
					locales="en-US"
					options={{ style: "currency", currency: "USD" }}
				/>
				<EditButton />
			</Datagrid>
		</List>
	</React.Fragment>
);
export default AttributeList;
