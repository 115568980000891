import DraftsIcon from "@material-ui/icons/Drafts";
import React from "react";
import {
	Button,
	useNotify,
	useRefresh,
	useUnselectAll,
	useUpdateMany,
} from "react-admin";

const ReadButton = ({ selectedIds }) => {
	const refresh = useRefresh();
	const notify = useNotify();
	const unselectAll = useUnselectAll();
	const [updateMany, { loading }] = useUpdateMany(
		"notifications",
		selectedIds,
		{ readed: new Date() },
		{
			onSuccess: () => {
				refresh();
				notify("Notifications readed");
				unselectAll("notifications");
			},
			onFailure: (error) =>
				notify("Error: notifications not updated", "warning"),
		}
	);

	return (
		<Button label="Sign as readed" disabled={loading} onClick={updateMany}>
			<DraftsIcon />
		</Button>
	);
};

export default ReadButton;
