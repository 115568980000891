import React from "react";
import { Filter, ReferenceInput, SelectInput, TextInput } from "react-admin";
const CustomerFilters = (props) => (
	<Filter {...props}>
		<TextInput source="q" label="Search..." alwaysOn />
		<ReferenceInput
			source="format"
			reference="item_formats"
			label="Formats"
			alwaysOn
		>
			<SelectInput optionText="name" />
		</ReferenceInput>
	</Filter>
);
export default CustomerFilters;
