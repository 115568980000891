import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import React from "react";
import {
	Button,
	useNotify,
	useRefresh,
	useUnselectAll,
	useUpdateMany,
} from "react-admin";

const PayButton = ({ selectedIds }) => {
	const refresh = useRefresh();
	const notify = useNotify();
	const unselectAll = useUnselectAll();
	const [updateMany, { loading }] = useUpdateMany(
		"orders",
		selectedIds,
		{ paid: new Date() },
		{
			onSuccess: () => {
				refresh();
				notify("Orders paid");
				unselectAll("orders");
			},
			onFailure: (error) => notify("Error: orders not updated", "warning"),
		}
	);

	return (
		<Button label="Pay" disabled={loading} onClick={updateMany}>
			<AttachMoneyIcon />
		</Button>
	);
};

export default PayButton;
