import React from "react";
import {
	ChipField,
	Datagrid,
	DateField,
	EditButton,
	List,
	SelectField,
	TextField,
} from "react-admin";
import Pagination from "../components/Pagination";
import Filters from "./Filters";
import languages from "./languages";
import platforms from "./platforms";

const WebsiteList = (props) => (
	<React.Fragment>
		<List
			{...props}
			exporter={false}
			perPage={25}
			filters={<Filters />}
			pagination={<Pagination />}
		>
			<Datagrid rowClick="edit">
				<TextField source="id" />
				<TextField source="name" style={{ fontWeight: "bold" }} />
				<ChipField source="code" />
				<SelectField source="platform" choices={platforms} />
				<SelectField source="language" choices={languages} />
				<DateField showTime source="created" />
				<DateField showTime source="modified" />
				<EditButton />
			</Datagrid>
		</List>
	</React.Fragment>
);
export default WebsiteList;
