import PrintIcon from "@material-ui/icons/Print";
import React from "react";
import { Button } from "react-admin";
import { API_URL } from "../config";

const PrintButton = ({ selectedIds }) => {
	const handleClick = (e) => {
		e.stopPropagation();
		e.preventDefault();
		window.open(
			`${API_URL}/orders/print?ids=${selectedIds.join(
				","
			)}&token=${localStorage.getItem("token")}`
		);
	};

	return (
		<Button label="Print" onClick={handleClick}>
			<PrintIcon />
		</Button>
	);
};

export default PrintButton;
