import GetAppIcon from "@material-ui/icons/GetApp";
import React from "react";
import { Button } from "react-admin";
import { API_URL } from "../config";

const DownloadButton = ({
	basePath = "",
	label = "Download",
	record,
	permissions,
	...rest
}) => {
	const downloadType =
		permissions && permissions("object") ? "drawings" : "order";
	const handleClick = (e) => {
		e.stopPropagation();
		e.preventDefault();
		window.open(
			`${API_URL}/orders/download/${
				record.id
			}?type=${downloadType}&token=${localStorage.getItem("token")}`
		);
	};
	return (
		<Button color="primary" label={label} onClick={handleClick} {...rest}>
			<GetAppIcon />
		</Button>
	);
};

export default DownloadButton;
