/**
 * https://material-ui.com/components/material-icons/
 */
import NotificationsIcon from "@material-ui/icons/Notifications";
import List from "./List";
import View from "./View";

export default {
	options: {
		group: "sales",
		roles: ["admin", "user", "designer", "object", "customer-service"],
	},
	icon: NotificationsIcon,
	list: List,
	show: View,
};
