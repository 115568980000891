import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import { get } from "lodash";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles({
	root: {
		display: "flex",
		color: red[500],
	},
});

const AlertField = ({ source, record = {}, ...props }) => {
	const value = get(record, source);
	const classes = useStyles();
	return (
		<Typography
			component="span"
			variant="caption"
			className={classes.root}
			{...props}
		>
			<Tooltip title={value ? value : ""}>
				{value !== null ? (
					<NewReleasesIcon data-testid="true" />
				) : (
					<span>&nbsp;</span>
				)}
			</Tooltip>
		</Typography>
	);
};

AlertField.propTypes = {
	label: PropTypes.string,
	record: PropTypes.object,
	source: PropTypes.string.isRequired,
};

export default AlertField;
