import RemoteErrorsInterceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import {
	BooleanInput,
	CheckboxGroupInput,
	Create,
	email,
	ReferenceArrayInput,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
} from "react-admin";
import { compose } from "recompose";

const FormatMany = (many) => (many ? many.map((r) => r.id) : []);
const ParseMany = (many) => (many ? many.map((id) => ({ id })) : []);

const MyCheckboxGroupInput = ({ loaded, ...props }) => (
	<CheckboxGroupInput
		validate={required()}
		{...props}
		choices={props.choices || []}
	/>
);

const UserCreate = ({ dispatch, validate, errors, permissions, ...props }) => {
	return (
		<Create {...props}>
			<SimpleForm redirect="list" validate={validate}>
				<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
				{permissions && permissions("admin") && (
					<ReferenceInput source="website_id" reference="websites" allowEmpty>
						<SelectInput />
					</ReferenceInput>
				)}
				<TextInput validate={[required(), email()]} source="email" />
				<TextInput validate={required()} source="username" />
				<TextInput validate={required()} source="password" type="password" />
				<TextInput
					validate={required()}
					source="profile.name"
					label="First name"
				/>
				<TextInput
					validate={required()}
					source="profile.surname"
					label="Last name"
				/>
				{permissions && permissions("admin") && (
					<BooleanInput source="profile.disable_email" label="Disable email" />
				)}
				{permissions && permissions("admin") && (
					<ReferenceArrayInput
						validate={required()}
						format={FormatMany}
						parse={ParseMany}
						source="roles"
						reference="roles"
						label="Roles"
					>
						<MyCheckboxGroupInput />
					</ReferenceArrayInput>
				)}
			</SimpleForm>
		</Create>
	);
};
export default compose(withErrors)(UserCreate);
