import {
	Card,
	CardContent,
	LinearProgress,
	makeStyles,
	Typography,
} from "@material-ui/core";
import { get } from "lodash";
import { stringify } from "query-string";
import React, { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";

const useStyles = makeStyles((theme) => ({
	root: {
		width: 200,
		marginLeft: theme.spacing(2),
		padding: theme.spacing(2),
		height: "fit-content",
	},
}));

const Aside = ({ filterValues, selectedIds, ...props }) => {
	const classes = useStyles();
	const [stats, setStats] = useState({});
	const [loading, setLoading] = useState(false);
	const dataProvider = useDataProvider();
	useEffect(() => {
		setLoading(true);
		dataProvider
			.getOne("orders/stats", {
				id: `?${
					stringify(filterValues) || "1=1"
				}&selectedIds=${selectedIds.join(",")}`,
			})
			.then(({ data }) => {
				setStats(data);
				setLoading(false);
			});
	}, [selectedIds, filterValues, dataProvider]);
	return (
		<Card className={classes.root}>
			<CardContent>
				{loading ? (
					<LinearProgress />
				) : (
					<>
						<Typography variant="p">Total cost of selected orders</Typography>
						<br />
						<br />
						<Typography variant="h4">
							{get(stats, "cost", 0).toLocaleString("en-US", {
								style: "currency",
								currency: "USD",
							})}
						</Typography>
					</>
				)}
			</CardContent>
		</Card>
	);
};

export default Aside;
