import { makeStyles } from "@material-ui/core";
import RemoteErrorsInterceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import {
	ArrayInput,
	Create,
	NumberInput,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	SimpleFormIterator,
	TextInput,
} from "react-admin";
import { compose } from "recompose";

const useStyles = makeStyles((theme) => ({
	arrayInput: {
		"& ul": {
			marginTop: "1.5em",
		},
	},
}));

const VariantCreate = ({ dispatch, validate, errors, ...props }) => {
	const classes = useStyles();
	return (
		<Create {...props}>
			<SimpleForm redirect="list" validate={validate}>
				<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
				<TextInput validate={[required()]} source="name" />
				<NumberInput source="cost" />
				<ArrayInput
					source="variants_attributes"
					label="Attributes"
					className={classes.arrayInput}
				>
					<SimpleFormIterator>
						<ReferenceInput
							source="attribute_id"
							reference="attributes"
							label="Attribute"
							sort={{ field: "name", order: "ASC" }}
							fullWidth
						>
							<SelectInput optionText="name" />
						</ReferenceInput>
					</SimpleFormIterator>
				</ArrayInput>
			</SimpleForm>
		</Create>
	);
};
export default compose(withErrors)(VariantCreate);
