/**
 * npm i --save @date-io/date-fns date-fns @material-ui/pickers
 */
import DateFnsUtils from "@date-io/date-fns";
import {
	DatePicker,
	DateTimePicker,
	MuiPickersUtilsProvider,
	TimePicker,
} from "@material-ui/pickers";
import "date-fns";
import React from "react";
import { useInput, useTranslate } from "react-admin";
import { useField } from "react-final-form";

const Picker = ({
	source,
	label,
	helperText,
	validate,
	component,
	resource,
	...props
}) => {
	const {
		input: { onChange, value },
	} = useField(source);
	const {
		meta: { touched, error },
	} = useInput(source);
	const translate = useTranslate();
	const properties = {
		name: source,
		value: value || null,
		onChange,
		showTodayButton: true,
		label: label || translate(`resources.${resource}.fields.${source}`),
		ampm: false,
		inputVariant: "filled",
		margin: "dense",
		required: typeof validate === "function" || typeof validate === "object",
		error: !!(touched && error),
		helperText:
			touched && error ? translate("ra.validation.required") : helperText,
		clearable: true,
		...props,
	};

	const getComponent = () => {
		switch (component) {
			case "TimePicker":
				return (
					<TimePicker
						{...properties}
						format={"dd/MM/yyyy HH:mm:ss"}
						showTodayButton={false}
					/>
				);
			case "DateTimePicker":
				return (
					<DateTimePicker {...properties} format={"dd/MM/yyyy HH:mm:ss"} />
				);
			default:
				return <DatePicker {...properties} format={"dd/MM/yyyy"} />;
		}
	};
	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			{getComponent()}
		</MuiPickersUtilsProvider>
	);
};

export const DateTimeInput = (props) => (
	<Picker component="DateTimePicker" {...props} />
);
export const DateInput = (props) => (
	<Picker component="DatePicker" {...props} />
);
export const TimeInput = (props) => (
	<Picker component="TimePicker" {...props} />
);
