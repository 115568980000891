import React from "react";
import Drawer from "@material-ui/core/Drawer";
import IconKeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { Button } from "react-admin";

const QuickView = ({
	basePath,
	id,
	record,
	open,
	toggle,
	children,
	...props
}) => {
	if (!open) return null;
	return (
		<Drawer anchor="right" open={open} onClose={() => toggle(false)}>
			<div>
				<Button label="ra.action.close" onClick={() => toggle(false)}>
					<IconKeyboardArrowRight />
				</Button>
			</div>
			{children}
		</Drawer>
	);
};

export default QuickView;
