import EditIcon from "@material-ui/icons/Edit";
import React, { useState } from "react";
import { Button } from "react-admin";
import EditAllDialog from "./EditAllDialog";

const EditAllButton = ({ basePath, resource, selectedIds }) => {
	const [showDialog, setShowDialog] = useState(false);
	return (
		<React.Fragment>
			<Button onClick={() => setShowDialog(true)} label="Edit all">
				<EditIcon />
			</Button>
			<EditAllDialog
				onClose={() => setShowDialog(false)}
				open={showDialog}
				basePath={basePath}
				resource={resource}
				selectedIds={selectedIds}
			/>
		</React.Fragment>
	);
};

export default EditAllButton;
