import React from "react";
import { Filter, SelectInput, TextInput } from "react-admin";
import languages from "./languages";
import platforms from "./platforms";
const WebsiteFilters = (props) => (
	<Filter {...props}>
		<TextInput source="q" label="Search..." alwaysOn />
		<SelectInput source="platform" choices={platforms} />
		<SelectInput source="language" choices={languages} />
	</Filter>
);
export default WebsiteFilters;
