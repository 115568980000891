import { makeStyles, useTheme } from "@material-ui/core";
import { get } from "lodash";
import React, { useState } from "react";
import {
	BulkDeleteButton,
	Datagrid,
	DateField,
	List,
	ShowButton,
	TextField,
} from "react-admin";
import Pagination from "../components/Pagination";
import QuickView from "../components/QuickView";
import SafeTag from "../components/SafeTag";
import Filters from "./Filters";
import OrderField from "./OrderField";
import ReadButton from "./ReadButton";
import UnreadButton from "./UnreadButton";
import View from "./View";

const useSyle = makeStyles({
	list: {
		"& table td, table th": {
			whiteSpace: "nowrap",
		},
	},
});

const NotificationBulkActionButtons = (props) => (
	<React.Fragment>
		<ReadButton label="Sign as readed" {...props} />
		<UnreadButton label="Sign as unreaded" {...props} />
		<BulkDeleteButton {...props} />
	</React.Fragment>
);

const NotificationList = (props) => {
	const [quickView, setQuickView] = useState({
		open: false,
		id: null,
		basePath: "",
		record: {},
	});
	const onRowClick = (id, basePath, record) => {
		setQuickView({ open: true, id, basePath, record, toggle: setQuickView });
	};
	const classes = useSyle();
	const theme = useTheme();
	const isDark = get(theme, "palette.type", null) === "dark";
	const rowStyle = (record) => {
		if (isDark) {
			return {
				backgroundColor: record.readed ? "#1b5e20" : "#ff6d00",
			};
		}
		return {
			backgroundColor: record.readed ? "#c8e6c9" : "#fff9c4",
		};
	};

	return (
		<>
			<QuickView {...quickView}>
				<View actions={<SafeTag />} {...props} {...quickView} />
			</QuickView>
			<List
				{...props}
				exporter={false}
				perPage={25}
				filters={<Filters />}
				sort={{ field: "created", order: "DESC" }}
				className={classes.list}
				bulkActionButtons={<NotificationBulkActionButtons />}
				pagination={<Pagination />}
			>
				<Datagrid rowClick={onRowClick} rowStyle={rowStyle}>
					<TextField source="title" style={{ fontWeight: "bold" }} />
					<OrderField source="order_id" reference="orders" />
					<DateField showTime source="readed" emptyText="Not readed yet" />
					<DateField showTime source="created" />
					<ShowButton onClick={onRowClick} />
				</Datagrid>
			</List>
		</>
	);
};
export default NotificationList;
