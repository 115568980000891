export default [
	{
		id: "en",
		name: "English",
	},
	{
		id: "it",
		name: "Italian",
	},
];
