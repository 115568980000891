export const codes = [
	{ id: "notify_created", name: "Created" },
	{ id: "notify_assigned_customer", name: "Assigned customer" },
	{ id: "notify_assigned_designer", name: "Assigned designer" },
	{ id: "notify_message_designer", name: "Message to designer" },
	{ id: "notify_message_admin", name: "Message to Admin" },
	{ id: "notify_drawings_loaded", name: "Drawings loaded" },
	{ id: "notify_under_review", name: "Under review" },
	{ id: "notify_completed", name: "Completed" },
	{ id: "notify_status_completed", name: "Notify status completed" },
	{ id: "notify_status_under_review", name: "Notify status under review" },
];
