// in src/MyUrlField.js
import React from "react";
import { ChipField } from "react-admin";

const isLight = (hexcolor) => {
	if (!hexcolor) {
		return false;
	}
	const hex = hexcolor.replace("#", "");
	const c_r = parseInt(hex.substr(0, 2), 16);
	const c_g = parseInt(hex.substr(2, 2), 16);
	const c_b = parseInt(hex.substr(4, 2), 16);
	const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
	return brightness > 155;
};

const ChipColorField = ({ record = {}, source, classes, ...attr }) => {
	return (
		<ChipField
			record={record}
			source={source}
			style={{
				background: record && record.color ? record.color : null,
				color: isLight(record && record.color ? record.color : "#FFFFFF")
					? "#000000"
					: "#FFFFFF",
			}}
			{...attr}
		></ChipField>
	);
};

export default ChipColorField;
