import React from "react";
import { Edit, SimpleForm, TextInput, required } from "react-admin";
import { compose } from "recompose";
import RemoteErrorsInterceptor, { withErrors } from "ra-remote-validator";
import Title from "../components/Title";

const RoleEdit = ({ dispatch, validate, errors, ...props }) => (
	<Edit title={<Title source="name" />} {...props} undoable={false}>
		<SimpleForm redirect="list" validate={validate}>
			<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
			<TextInput validate={required()} source="code" />
			<TextInput validate={required()} source="name" />
		</SimpleForm>
	</Edit>
);
export default compose(withErrors)(RoleEdit);
