import {
	AppBar as MuiAppBar,
	Badge,
	IconButton,
	makeStyles,
	Toolbar,
	Tooltip,
	Typography,
	useMediaQuery,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/Notifications";
import classNames from "classnames";
import PropTypes from "prop-types";
import { toggleSidebar, useTranslate } from "ra-core";
import { HideOnScroll, LoadingIndicator } from "ra-ui-materialui";
import React, { Children, cloneElement } from "react";
import { useQuery, useRedirect } from "react-admin";
import { useDispatch } from "react-redux";
import UserMenu from "./UserMenu";

const useStyles = makeStyles(
	(theme) => ({
		toolbar: {
			paddingRight: 24,
		},
		menuButton: {
			marginLeft: "0.5em",
			marginRight: "0.5em",
		},
		menuButtonIconClosed: {
			transition: theme.transitions.create(["transform"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			transform: "rotate(0deg)",
		},
		menuButtonIconOpen: {
			transition: theme.transitions.create(["transform"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			transform: "rotate(180deg)",
		},
		title: {
			flex: 1,
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			overflow: "hidden",
		},
	}),
	{ name: "RaAppBar" }
);

const AppBar = (props) => {
	const {
		children,
		classes: classesOverride,
		className,
		color = "secondary",
		logo,
		logout,
		open,
		title,
		userMenu = <UserMenu />,
		...rest
	} = props;
	const classes = useStyles(props);
	const dispatch = useDispatch();
	const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
	const translate = useTranslate();
	const redirect = useRedirect();

	const { data: notifications } = useQuery({
		type: "getOne",
		resource: "notifications/status",
		payload: {
			id: "unreaded",
		},
	});

	return (
		<HideOnScroll>
			<MuiAppBar className={className} color={color} {...rest}>
				<Toolbar
					disableGutters
					variant={isXSmall ? "regular" : "dense"}
					className={classes.toolbar}
				>
					<Tooltip
						title={translate(
							open ? "ra.action.close_menu" : "ra.action.open_menu",
							{
								_: "Open/Close menu",
							}
						)}
						enterDelay={500}
					>
						<IconButton
							color="inherit"
							onClick={() => dispatch(toggleSidebar())}
							className={classNames(classes.menuButton)}
						>
							<MenuIcon
								classes={{
									root: open
										? classes.menuButtonIconOpen
										: classes.menuButtonIconClosed,
								}}
							/>
						</IconButton>
					</Tooltip>
					{Children.count(children) === 0 ? (
						<Typography
							variant="h6"
							color="inherit"
							className={classes.title}
							id="react-admin-title"
						/>
					) : (
						children
					)}
					<LoadingIndicator />
					<IconButton
						color="inherit"
						onClick={() => redirect("/notifications")}
						className={classNames(classes.menuButton)}
					>
						<Badge
							badgeContent={notifications && notifications.unreaded}
							color="error"
						>
							<NotificationsIcon />
						</Badge>
					</IconButton>
					{cloneElement(userMenu, { logout })}
				</Toolbar>
			</MuiAppBar>
		</HideOnScroll>
	);
};

AppBar.propTypes = {
	children: PropTypes.node,
	classes: PropTypes.object,
	className: PropTypes.string,
	color: PropTypes.oneOf([
		"default",
		"inherit",
		"primary",
		"secondary",
		"transparent",
	]),
	logout: PropTypes.element,
	open: PropTypes.bool,
	userMenu: PropTypes.element,
};

AppBar.defaultProps = {
	userMenu: <UserMenu />,
};

export default AppBar;
