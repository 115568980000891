import List from "./List";
import Edit from "./Edit";
import Create from "./Create";
import { UserIcon } from "../icons";

export default {
	options: {
		group: "admin",
		roles: ["admin"],
	},
	icon: UserIcon,
	list: List,
	edit: Edit,
	create: Create,
};
