import LinearProgress from "@material-ui/core/LinearProgress";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import React, { useState } from "react";
import { Button, useNotify, useRedirect } from "react-admin";
import authProvider from "../authProvider";

const LoginButton = ({ basePath = "", label = "Login", record, ...rest }) => {
	const [loading, setLoading] = useState(false);
	const redirect = useRedirect();
	const notify = useNotify();
	const handleClick = () => {
		setLoading(true);
		authProvider("subLogin", { sub_user_id: record.id })
			.then((success) => {
				setLoading(false);
				notify("Login successfully", "success");
				redirect("/");
				setTimeout(() => window.location.reload(), 200);
			})
			.catch((error) => {
				setLoading(false);
				notify("Cant login in this user profile", "warning");
			});
	};

	if (loading) return <LinearProgress />;

	return (
		<Button color="secondary" label={label} onClick={handleClick} {...rest}>
			<VpnKeyIcon />
		</Button>
	);
};

export default LoginButton;
