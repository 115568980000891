import { makeStyles } from "@material-ui/core";
import React from "react";
import {
	ChipField,
	Datagrid,
	DateField,
	EmailField,
	List,
	TextField,
} from "react-admin";
import Pagination from "../components/Pagination";
import Filters from "./Filters";

const useStyles = makeStyles({
	list: {
		"& table td, table th": {
			whiteSpace: "nowrap",
		},
	},
});

const CustomersList = (props) => {
	const classes = useStyles();
	return (
		<React.Fragment>
			<List
				{...props}
				perPage={25}
				filters={<Filters />}
				sort={{ field: "created", order: "DESC" }}
				pagination={<Pagination />}
				className={classes.list}
			>
				<Datagrid>
					<TextField source="id" />
					<ChipField source="fullname" />
					<TextField source="address" />
					<TextField source="city" />
					<TextField source="zip_code" />
					<TextField source="phone" />
					<EmailField source="email" />
					<DateField showTime source="created" />
					<DateField showTime source="modified" />
				</Datagrid>
			</List>
		</React.Fragment>
	);
};
export default CustomersList;
