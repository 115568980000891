import PermMediaIcon from "@material-ui/icons/PermMedia";
import List from "./List";

export default {
	options: {
		group: "admin",
		roles: ["admin"],
	},
	icon: PermMediaIcon,
	list: List,
};
