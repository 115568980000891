import React from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { Login } from "react-admin";
import bg from "./bg.png";

const styles = (theme) =>
	createStyles({
		login: {
			background: "#FEC100",
		},
	});

const CustomLoginPage = ({ classes }) => (
	<Login backgroundImage={bg} className={classes.login} />
);

export default withStyles(styles)(CustomLoginPage);
