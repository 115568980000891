/**
 * https://material-ui.com/components/material-icons/
 */
import { UserIcon } from "../icons";
// import Create from "./Create";
// import Edit from "./Edit";
import List from "./List";

export default {
	options: {
		group: "admin",
		roles: ["admin"],
	},
	icon: UserIcon,
	list: List,
	// edit: Edit,
	// create: Create,
};
