import { LinearProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import { ChipField, ReferenceField, useRedirect, useUpdate } from "react-admin";

const OrderField = ({ record, ...props }) => {
	const [isRedirecting, setIsRedirecting] = React.useState(false);
	const [update, { loading }] = useUpdate(
		"notifications",
		record.id,
		{ readed: new Date() },
		record
	);
	const redirect = useRedirect();

	useEffect(() => {
		if (loading === false && isRedirecting === true) {
			redirect(`/orders/${record.order_id}`);
		}
	}, [loading, isRedirecting, redirect, record.order_id]);

	if (loading) {
		return <LinearProgress />;
	}

	return (
		<ReferenceField
			{...props}
			loading={loading}
			record={record}
			source="order_id"
			reference="orders"
			onClick={(e) => {
				e.stopPropagation();
				e.preventDefault();
				if (!record?.readed) {
					setIsRedirecting(true);
					update();
				} else {
					redirect(`/orders/${record.order_id}`);
				}
			}}
		>
			<ChipField source="code" />
		</ReferenceField>
	);
};

export default OrderField;
