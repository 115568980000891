/**
 * https://material-ui.com/components/material-icons/
 */

import LanguageIcon from "@material-ui/icons/Language";
import Create from "./Create";
import Edit from "./Edit";
import List from "./List";

export default {
	options: {
		group: "admin",
		roles: ["admin"],
	},
	icon: LanguageIcon,
	list: List,
	edit: Edit,
	create: Create,
};
