import {
	createStyles,
	makeStyles,
	Typography,
	withStyles,
} from "@material-ui/core";
import SubMenuIcon from "@material-ui/icons/ChevronRight";
import { WithPermissions } from "ra-core";
import React, { createElement, useState } from "react";
import { getResources, MenuItemLink, Responsive } from "react-admin";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { VERSION } from "./config";
import customRoutes from "./customRoutes";
import { DashboardIcon } from "./icons";
import SubMenu from "./SubMenu";

const groupOrders = {
	sales: 1,
	admin: 2,
};

const styles = (theme) =>
	createStyles({
		version: {
			padding: theme.spacing(1),
		},
	});

const useStyles = makeStyles({
	link: {
		textTransform: "capitalize",
	},
});

const mapGroups = (resources, permissions, hasDashboard) => {
	let groups = (hasDashboard
		? [
				{
					path: "/",
					name: "dashboard",
					icon: DashboardIcon,
					options: {
						group: "dashboard",
						accessible: true,
					},
				},
		  ]
		: []
	)
		.concat(resources.filter((r) => r.hasList))
		.concat(
			customRoutes.map((cr) => ({
				path: cr.props.path,
				icon: cr.props.options.icon,
				options: cr.props.options,
			}))
		)
		.filter(
			(item) =>
				permissions &&
				(item.options.roles === undefined ||
					item.options.roles.filter((role) => permissions(role)).length > 0)
		)
		.reduce((groups, resource) => {
			let groupName = resource.options ? resource.options.group : "";
			let group = groups.find((g) => g.name === groupName);
			if (group) {
				group.resources.push(resource);
			} else {
				group = {
					name: groupName,
					order: groupOrders[groupName] || 1000,
					resources: [resource],
				};
				groups.push(group);
			}
			return groups;
		}, []);
	groups.sort((a, b) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0));

	return groups;
};

const capitalize = (s) => {
	if (typeof s !== "string") return "";
	return s.charAt(0).toUpperCase() + s.slice(1);
};

const Menu = ({
	resources,
	onMenuClick,
	logout,
	open,
	classes,
	location,
	hasDashboard,
}) => {
	const [visibility, setVisibility] = useState({
		dashboard: true,
		sales: true,
		admin: true,
	});
	const _classes = useStyles();
	return (
		<WithPermissions
			render={({ permissions }) => (
				<div>
					{mapGroups(resources, permissions, hasDashboard).map((group) => (
						<SubMenu
							key={group.name}
							handleToggle={() =>
								setVisibility({
									...visibility,
									[group.name]: visibility[group.name] !== true,
								})
							}
							isOpen={
								visibility[group.name] ||
								group.resources.some(
									(resource) =>
										location.pathname === `/${resource.name}` ||
										location.pathname === resource.path ||
										location.pathname.indexOf(`/${resource.name}?`) === 0 ||
										location.pathname.indexOf(`/${resource.name}/`) === 0
								)
							}
							sidebarIsOpen={open}
							name={capitalize(group.name)}
							icon={<SubMenuIcon />}
						>
							{group.resources.map((resource) => {
								let to = `${resource.path || `/${resource.name}`}`;
								return (
									<MenuItemLink
										className={_classes.link}
										key={resource.path || resource.name}
										to={to}
										isActive={(match, location) =>
											location.pathname === `/${resource.name}` ||
											location.pathname === resource.path ||
											location.pathname.indexOf(`/${resource.name}?`) === 0 ||
											location.pathname.indexOf(`/${resource.name}/`) === 0
										}
										primaryText={
											resource.options && resource.options.title
												? resource.options.title
												: resource.options && resource.options.label
												? resource.options.label
												: resource.name
										}
										leftIcon={createElement(resource.icon)}
										onClick={onMenuClick}
									/>
								);
							})}
						</SubMenu>
					))}
					<Typography
						className={classes.version}
						variant="caption"
						component="p"
					>
						{VERSION}
					</Typography>
					<Responsive
						small={logout}
						medium={null} // Pass null to render nothing on larger devices
					/>
				</div>
			)}
		/>
	);
};

const mapStateToProps = (state) => ({
	open: state.admin.ui.sidebarOpen,
	resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(withStyles(styles)(Menu)));
