/**
 * https://material-ui.com/components/material-icons/
 */
import EditAttributesIcon from "@material-ui/icons/EditAttributes";
import Create from "./Create";
import Edit from "./Edit";
import List from "./List";

export default {
	options: {
		group: "admin",
		roles: ["admin"],
	},
	icon: EditAttributesIcon,
	list: List,
	edit: Edit,
	create: Create,
};
