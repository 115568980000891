import RemoteErrorsInterceptor, { withErrors } from "ra-remote-validator";
import React, { useEffect } from "react";
import {
	Create,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
	useRedirect,
} from "react-admin";
import { compose } from "recompose";

const OrderCreate = ({ dispatch, validate, errors, permissions, ...props }) => {
	const redirect = useRedirect();
	useEffect(() => {
		if (permissions && !permissions("admin")) {
			redirect("/orders");
		}
	}, [permissions, redirect]);
	return (
		<Create {...props}>
			<SimpleForm redirect="edit" validate={validate}>
				<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
				<ReferenceInput
					source="website_id"
					reference="websites"
					validate={[required()]}
				>
					<SelectInput />
				</ReferenceInput>
				<TextInput
					multiline
					rows={30}
					source="r_data"
					validate={[required()]}
					fullWidth
					label="JSON Data"
				/>
			</SimpleForm>
		</Create>
	);
};
export default compose(withErrors)(OrderCreate);
