import React, { Fragment } from "react";
import { BulkDeleteButton } from "react-admin";
import EditAllButton from "./EditAllButton";
import PayButton from "./PayButton";
import PrintButton from "./PrintButton";
import UnpayButton from "./UnpayButton";

const BulkActionButtons = ({ permissions, ...props }) => {
	return (
		<Fragment>
			{/* default bulk delete action */}
			<PrintButton {...props} />
			{permissions && permissions("admin") && <PayButton {...props} />}
			{permissions && permissions("admin") && <UnpayButton {...props} />}
			{permissions && permissions("admin") && <EditAllButton {...props} />}
			{permissions && permissions("admin") && <BulkDeleteButton {...props} />}
		</Fragment>
	);
};

export default BulkActionButtons;
