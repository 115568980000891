import React from "react";
import { AutocompleteInput, Filter, ReferenceInput } from "react-admin";
const DrawingFilters = (props) => (
	<Filter {...props}>
		<ReferenceInput source="user_id" label="Designer" reference="users">
			<AutocompleteInput />
		</ReferenceInput>
		<ReferenceInput source="order_id" reference="orders">
			<AutocompleteInput optionText="code" />
		</ReferenceInput>
	</Filter>
);
export default DrawingFilters;
