import React from "react";
import { DateField, Show, SimpleShowLayout, TextField } from "react-admin";

const NotificationView = (props) => (
	<Show {...props}>
		<SimpleShowLayout>
			<TextField source="title" style={{ fontWeight: "bold" }} />
			<TextField source="content" />
			<DateField showTime source="readed" emptyText="Not readed yet" />
			<DateField showTime source="created" />
		</SimpleShowLayout>
	</Show>
);

export default NotificationView;
