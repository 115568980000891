import { errorsReducer, errorsSaga } from "ra-remote-validator";
import React from "react";
import { Admin, Resource } from "react-admin";
import { compose } from "recompose";
import addUploadFeature from "./addUploadFeature";
import authProvider from "./authProvider";
import CustomLoginPage from "./customLoginPage";
import dataProvider from "./dataProvider";
import Layout from "./Layout";
import Menu from "./Menu";
import resources from "./resources";
import theme from "./theme";

const dp = compose(addUploadFeature)(dataProvider);

const App = () => (
	<Admin
		theme={theme}
		menu={Menu}
		customSagas={[errorsSaga]}
		customReducers={{
			errors: errorsReducer,
		}}
		dataProvider={dp}
		authProvider={authProvider}
		loginPage={CustomLoginPage}
		appLayout={Layout}
	>
		{resources.map((resource, i) => (
			<Resource key={i} {...resource} />
		))}
	</Admin>
);

export default App;
