import React from "react";
import {
	ArrayField,
	BooleanField,
	ChipField,
	Datagrid,
	EditButton,
	List,
	NumberField,
	ReferenceField,
	SingleFieldList,
	TextField,
} from "react-admin";
import Pagination from "../components/Pagination";
import Filters from "./Filters";
import LoginButton from "./LoginButton";
const UserList = (props) => (
	<React.Fragment>
		<List
			{...props}
			exporter={false}
			perPage={25}
			filters={<Filters />}
			sort={{ field: "id", order: "desc" }}
			pagination={<Pagination />}
		>
			<Datagrid rowClick="edit">
				<TextField source="id" />
				<TextField source="username" style={{ fontWeight: "bold" }} />
				<TextField source="email" />
				<ReferenceField source="website_id" reference="websites">
					<ChipField source="name" />
				</ReferenceField>
				<NumberField source="orders_count" />
				<BooleanField source="is_active" />
				<ArrayField source="roles" sortable={false}>
					<SingleFieldList>
						<ChipField source="name" />
					</SingleFieldList>
				</ArrayField>
				<LoginButton />
				<EditButton />
			</Datagrid>
		</List>
	</React.Fragment>
);
export default UserList;
