import attributes from "./attributes";
import customers from "./customers";
import drawings from "./drawings";
import emailTemplates from "./email_templates";
import notifications from "./notifications";
import orders from "./orders";
import roles from "./roles";
import statuses from "./statuses";
import users from "./users";
import variants from "./variants";
import websites from "./websites";

export default [
	{ name: "orders", ...orders },
	{ name: "notifications", ...notifications },
	{ name: "drawings", ...drawings },
	{ name: "customers", ...customers },
	{ name: "attributes", ...attributes },
	{ name: "variants", ...variants },
	{ name: "statuses", ...statuses },
	{ name: "websites", ...websites },
	{ name: "email_templates", ...emailTemplates },
	{ name: "items" },
	{ name: "item_formats" },
	{ name: "users", ...users },
	{ name: "roles", ...roles },
];
