import RemoteErrorsInterceptorr, { withErrors } from "ra-remote-validator";
import React from "react";
import {
	Create,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
} from "react-admin";
import { compose } from "recompose";
import CodeInput from "../components/CodeInput";
import { codes } from "./codes";
import Placeholders from "./Placeholders";

const EmailTemplateCreate = ({ dispatch, validate, errors, ...props }) => {
	return (
		<Create {...props}>
			<SimpleForm redirect="edit" validate={validate}>
				<RemoteErrorsInterceptorr errors={errors} dispatch={dispatch} />
				<ReferenceInput
					validate={[required()]}
					source="website_id"
					reference="websites"
				>
					<SelectInput />
				</ReferenceInput>
				<TextInput validate={[required()]} source="name" />
				<SelectInput validate={[required()]} source="code" choices={codes} />
				<TextInput validate={[required()]} source="subject" fullWidth />
				<CodeInput validate={[required()]} source="body" fullWidth />
				<Placeholders />
			</SimpleForm>
		</Create>
	);
};
export default compose(withErrors)(EmailTemplateCreate);
