import React from "react";
import {
	AutocompleteInput,
	BooleanInput,
	Filter,
	ReferenceInput,
	SelectInput,
	TextInput,
} from "react-admin";
import { DateTimeInput } from "../components/DatePickers";
const OrderFilters = ({ permissions, ...props }) => {
	const hasRoles = (roles) => {
		if (!permissions) {
			return false;
		}
		var has = false;
		roles.forEach((r) => {
			if (permissions(r)) {
				has = true;
			}
		});
		return has;
	};
	return (
		<Filter {...props}>
			<TextInput source="q" label="Search..." alwaysOn />
			<TextInput source="code" />
			<ReferenceInput
				source="status_id"
				reference="statuses"
				sort={{ field: "id", order: "ASC" }}
			>
				<SelectInput optionText="name" />
			</ReferenceInput>
			{hasRoles(["admin"]) && (
				<ReferenceInput
					source="user_id"
					reference="users"
					sort={{ field: "username", order: "ASC" }}
					label="Designer"
				>
					<AutocompleteInput optionText="username" />
				</ReferenceInput>
			)}
			{hasRoles(["admin"]) && (
				<ReferenceInput
					source="website_id"
					reference="websites"
					label="Website"
				>
					<SelectInput optionText="name" />
				</ReferenceInput>
			)}
			{hasRoles(["admin", "customer-service", "object", "designer"]) && (
				<ReferenceInput
					source="format"
					reference="item_formats"
					label="Formats"
				>
					<SelectInput optionText="name" />
				</ReferenceInput>
			)}
			{hasRoles(["admin"]) && (
				<DateTimeInput
					source="customer_expiration_date_start"
					label="Customer expiration date from"
				/>
			)}
			{hasRoles(["admin"]) && (
				<DateTimeInput
					source="customer_expiration_date_end"
					label="Customer expiration date to"
				/>
			)}
			<DateTimeInput
				source="designer_expiration_date_start"
				label={
					hasRoles(["admin"])
						? "Designer expiration date from"
						: "Expiration date from"
				}
			/>
			<DateTimeInput
				source="designer_expiration_date_end"
				label={
					hasRoles(["admin"])
						? "Designer expiration date to"
						: "Expiration date to"
				}
			/>
			{hasRoles(["admin"]) && <BooleanInput source="paid" label="Paid" />}
			{hasRoles(["object"]) && (
				<BooleanInput source="printed" label="Printed" />
			)}
			{hasRoles(["admin", "object", "customer-service", "designer"]) && (
				<BooleanInput source="fast_shipping" />
			)}
			{hasRoles(["admin", "object", "customer-service"]) && (
				<BooleanInput source="status_alert" />
			)}
			<DateTimeInput source="created_start" label="Created from" />
			<DateTimeInput source="created_end" label="Created to" />
		</Filter>
	);
};
export default OrderFilters;
