import { Grid, makeStyles } from "@material-ui/core";
import { get } from "lodash";
import RemoteErrorsInterceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import {
	ArrayField,
	ArrayInput,
	AutocompleteInput,
	BooleanInput,
	ChipField,
	Datagrid,
	DateField,
	DeleteButton,
	Edit,
	FormDataConsumer,
	FormTab,
	ImageInput,
	ReferenceField,
	ReferenceInput,
	ReferenceManyField,
	required,
	SaveButton,
	SelectInput,
	SimpleFormIterator,
	SingleFieldList,
	TabbedForm,
	TextField,
	TextInput,
	Toolbar,
} from "react-admin";
import { compose } from "recompose";
import ChipColorField from "../components/ChipColorField";
import { DateTimeInput } from "../components/DatePickers";
import RepliesField from "../components/RepliesField";
import Title from "../components/Title";
import { APP_URL } from "../config";
import ImageField from "../components/ImageField";

const OrderEditToolbar = ({ permissions, hasRoles, ...props }) => {
	const classes = useStyles();
	return (
		<Toolbar {...props} className={classes.toolbar}>
			<SaveButton
				label="Save"
				redirect={false}
				submitOnEnter={false}
				variant="contained"
			/>
			{hasRoles(["admin", "customer-service"]) && <DeleteButton {...props} />}
		</Toolbar>
	);
};

const formatSrc = (r) =>
	r
		? {
			...r,
			src: r.src
				? r.src.startsWith("blob:")
					? r.src
					: `${APP_URL}${r.src}`
				: null,
		}
		: null;

const useStyles = makeStyles((theme) => ({
	arrayInput: {
		"& ul": {
			marginTop: "1.5em",
		},
	},
	arrayField: {
		width: "100%",
	},
	toolbar: {
		paddingLeft: theme.spacing(2),
		justifyContent: "space-between",
		display: "flex",
	},
	button: {
		marginRight: theme.spacing(1),
		overflow: "hidden",
	},
}));

const OrderEdit = ({ dispatch, validate, errors, permissions, ...props }) => {
	const classes = useStyles();
	const hasRoles = (roles) => {
		if (!permissions) {
			return false;
		}
		var has = false;
		roles.forEach((r) => {
			if (permissions(r)) {
				has = true;
			}
		});
		return has;
	};
	return (
		<Edit title={<Title source="name" />} {...props} undoable={false}>
			<TabbedForm
				validate={validate}
				redirect={false}
				toolbar={
					<OrderEditToolbar permissions={permissions} hasRoles={hasRoles} />
				}
			>
				<FormTab label="summary">
					<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
					{hasRoles(["admin", "customer-service"]) && (
						<ReferenceManyField
							label="Formats"
							reference="items"
							target="order_id"
						>
							<SingleFieldList>
								<ChipField source="format" />
							</SingleFieldList>
						</ReferenceManyField>
					)}
					<TextInput disabled source="code" validate={required()} />
					{hasRoles(["admin", "customer-service"]) && (
						<ReferenceInput
							source="status_id"
							reference="statuses"
							sort={{ field: "id", order: "ASC" }}
						>
							<SelectInput optionText="name" />
						</ReferenceInput>
					)}
					{hasRoles(["admin", "customer-service"]) && (
						<TextInput multiline source="status_notes" />
					)}
					{hasRoles(["admin", "customer-service"]) && (
						<ReferenceInput
							source="user_id"
							reference="users"
							sort={{ field: "username", order: "ASC" }}
							label="Designer"
						>
							<AutocompleteInput optionText="username" />
						</ReferenceInput>
					)}
					{hasRoles(["admin", "customer-service"]) && <br />}
					{hasRoles(["admin", "customer-service"]) && (
						<DateTimeInput
							source="customer_expiration_date"
							label="Customer expiration date"
							disablePast
						/>
					)}
					{hasRoles(["admin", "customer-service"]) && <br />}
					<DateTimeInput
						source="designer_expiration_date"
						label="Designer expiration date"
						disabled={!hasRoles(["admin", "customer-service"])}
					/>
					{hasRoles(["admin", "customer-service"]) && <br />}
					{hasRoles(["admin", "customer-service"]) && (
						<DateTimeInput source="paid" label="Paid date" />
					)}
					{hasRoles(["admin", "customer-service"]) && <br />}
					{hasRoles(["admin", "customer-service"]) && (
						<DateTimeInput source="created" disabled label="Created" />
					)}
				</FormTab>
				<FormTab label="items">
					<ArrayInput source="items" className={classes.arrayInput}>
						<SimpleFormIterator disableAdd>
							<TextInput disabled source="format" label="Format" fullWidth />
							<ReferenceInput
								reference="variants"
								source="variant_id"
								label="Variant"
								disabled={!hasRoles(["admin", "customer-service"])}
								fullWidth
							>
								<SelectInput />
							</ReferenceInput>
							<TextInput
								disabled={!hasRoles(["admin", "customer-service"])}
								source="notes"
								multiline
								rows={5}
								label="Customer notes"
								fullWidth
							/>
							<FormDataConsumer>
								{({
									formData, // The whole form data
									scopedFormData, // The data for this item of the ArrayInput
									getSource, // A function to get the valid source inside an ArrayInput
									...rest
								}) => {
									if (!get(scopedFormData, "id")) {
										return null;
									}
									return (
										<ArrayInput
											source={getSource("images")}
											label="Images"
											className={classes.arrayInput}
										>
											<SimpleFormIterator
												disableAdd={!hasRoles(["admin", "customer-service"])}
												disableRemove={!hasRoles(["admin", "customer-service"])}
											>
												<TextInput
													source="item_id"
													defaultValue={scopedFormData.id}
													style={{ display: "none" }}
												/>
												<ImageInput
													disabled={!hasRoles(["admin", "customer-service"])}
													source="image"
													accept="image/*"
													label="Image"
													format={formatSrc}
													validate={required()}
												>
													<ImageField source="src" title="name" />

												</ImageInput>

												<BooleanInput
													source="from_customer"
													label="Loaded from customer"
													disabled
												/>
											</SimpleFormIterator>
										</ArrayInput>
									);
								}}
							</FormDataConsumer>
						</SimpleFormIterator>
					</ArrayInput>
				</FormTab>
				<FormTab label="drawings">
					<FormDataConsumer>
						{({ formData, ...rest }) => (
							<Grid container>
								<Grid xs={12} item>
									<ArrayInput source="drawings" className={classes.arrayInput}>
										<SimpleFormIterator
											disableAdd={
												get(formData, "drawings", []).length >=
												get(formData, "items_count", 0)
											}
										>
											<ImageInput
												source="image"
												accept="image/*"
												label="Image"
												format={formatSrc}
												validate={required()}
											>
												<ImageField source="src" title="name" />
											</ImageInput>
											<TextInput
												source="notes"
												multiline
												rows={5}
												label="Notes"
												fullWidth
											/>
										</SimpleFormIterator>
									</ArrayInput>
								</Grid>
							</Grid>
						)}
					</FormDataConsumer>
				</FormTab>
				<FormTab label="messages">
					<RepliesField source="messages" />
					<TextInput
						source="send_message"
						multiline
						rows={8}
						fullWidth
						label="Send message"
					/>
				</FormTab>
				<FormTab label="Statuses">
					<ArrayField
						source="orders_statuses"
						label="Status list"
						className={classes.arrayField}
					>
						<Datagrid>
							<ReferenceField source="status_id" reference="statuses">
								<ChipColorField source="name" />
							</ReferenceField>
							<TextField source="notes" />
							<DateField showTime source="created" />
						</Datagrid>
					</ArrayField>
				</FormTab>
			</TabbedForm>
		</Edit>
	);
};
export default compose(withErrors)(OrderEdit);
