import {
	Avatar,
	Divider,
	ListItem,
	ListItemAvatar,
	ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import React, { Fragment } from "react";
import { ArrayInput, translate } from "react-admin";
import { compose } from "recompose";
import RepliesFormIterator from "./RepliesFormIterator";
const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		backgroundColor: theme.palette.background.paper,
	},
	inline: {
		display: "inline",
	},
	divider: {
		marginBottom: theme.spacing(1),
	},
	doc: {
		marginLeft: 40 + theme.spacing(2),
	},
	leave: {
		marginLeft: 72,
		width: "calc(100% - 72px)",
	},
}));
const ReplyItem = compose(translate)(({ translate, record, ...props }) => {
	const classes = useStyles();
	if (record.id > 0) {
		return (
			<Fragment key={record.id}>
				<ListItem alignItems="flex-start">
					<ListItemAvatar>
						<Avatar>
							{record.from_user
								? record.from_user.username.substr(0, 1).toUpperCase()
								: "S"}
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={moment(record.created).format("DD/MM/YYYY HH:mm:ss")}
						secondary={record.message}
					/>
				</ListItem>
				<Divider variant="inset" component="li" className={classes.divider} />
			</Fragment>
		);
	}
	return null;
});

const RepliesField = (props) => {
	const classes = useStyles();
	if (
		props.record &&
		props.record.replies &&
		props.record.status.can_reply === true &&
		props.record.replies.find((r) => r.id === undefined) === undefined
	) {
		props.record.replies.push({ id: undefined, message: "" });
	}
	return (
		<ArrayInput {...props} className={classes.root}>
			<RepliesFormIterator disableRemove={true} disableAdd={true}>
				<ReplyItem />
			</RepliesFormIterator>
		</ArrayInput>
	);
};

export default RepliesField;
