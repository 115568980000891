import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import get from "lodash/get";
import omit from "lodash/omit";
import PropTypes from "prop-types";
import React from "react";
import HEICImageDisplay from './HEICImageDisplay'; 

const sanitizeRestProps = (props) =>
    omit(props, [
        "addLabel",
        "allowEmpty",
        "basePath",
        "cellClassName",
        "className",
        "emptyText",
        "formClassName",
        "headerClassName",
        "label",
        "linkType",
        "link",
        "locale",
        "record",
        "resource",
        "sortable",
        "sortBy",
        "source",
        "textAlign",
        "translateChoice",
    ]);

const useStyles = makeStyles(
    {
        list: {
            display: "flex",
            listStyleType: "none",
        },
        image: {
            margin: "0.5rem",
            maxHeight: "10rem",
        },
    },
    { name: "RaImageField" }
);

const ImageField = (props) => {
    const {
        className,
        classes: classesOverride,
        emptyText,
        record,
        source,
        src,
        title,
        ...rest
    } = props;
    const sourceValue = get(record, source);
    const classes = useStyles(props);

    if (!sourceValue) {
        return emptyText ? (
            <Typography
                component="span"
                variant="body2"
                className={className}
                {...sanitizeRestProps(rest)}
            >
                {emptyText}
            </Typography>
        ) : (
            <div className={className} {...sanitizeRestProps(rest)} />
        );
    }

    if (Array.isArray(sourceValue)) {
        return (
            <ul
                className={classnames(classes.list, className)}
                {...sanitizeRestProps(rest)}
            >
                {sourceValue.map((file, index) => {
                    const fileTitleValue = get(file, title) || title;
                    const srcValue = get(file, src) || title;
                    const isHEIC = srcValue && srcValue.toLowerCase().endsWith('.heic');
                    return isHEIC ? (
                        <li key={index}>
                            <HEICImageDisplay heicImageUrl={`${srcValue}`} />
                        </li>
                    ) : (
                        <li key={index}>
                            <img
                                alt={fileTitleValue}
                                title={fileTitleValue}
                                src={`${srcValue}`}
                                className={classes.image}
                            />
                        </li>
                    );
                })}
            </ul>
        );
    }

    const titleValue = get(record, title) || title;
    const isHEIC = sourceValue.endsWith('.heic');

    return (
        <div className={className} {...sanitizeRestProps(rest)}>
            {isHEIC ? (
                <HEICImageDisplay heicImageUrl={`${sourceValue}`} />
            ) : (
                <img
                    title={titleValue}
                    alt={titleValue}
                    src={`${sourceValue}`}
                    className={classes.image}
                />
            )}
        </div>
    );
};

ImageField.displayName = "ImageField";

ImageField.defaultProps = {
    addLabel: true,
};

ImageField.propTypes = {
    src: PropTypes.string,
    title: PropTypes.string,
};

export default ImageField;

