import React, { useState, useEffect } from 'react';
import heic2any from 'heic2any';

function HEICImageDisplay({ heicImageUrl }) {
  const [convertedImageUrl, setConvertedImageUrl] = useState(null);

  useEffect(() => {
    fetch(heicImageUrl)
      .then(response => response.blob())
      .then(blob => heic2any({ blob, toType: 'image/jpeg' }))
      .then(conversionResult => {
        const url = URL.createObjectURL(conversionResult);
        setConvertedImageUrl(url);
      })
      .catch(error => console.error('HEIC conversion error:', error));
  }, [heicImageUrl]);

  if (!convertedImageUrl) {
    return <div>Loading...</div>;
  }

  return (
    <img src={convertedImageUrl} alt="Converted HEIC" style={{maxHeight: '200px'}}/>
  );
}

export default HEICImageDisplay;
