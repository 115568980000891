import React from "react";
import {
	AutocompleteInput,
	BooleanInput,
	Filter,
	ReferenceInput,
	TextInput,
} from "react-admin";
const NotificationFilters = (props) => (
	<Filter {...props}>
		<TextInput source="q" label="Search..." alwaysOn />
		<BooleanInput source="readed" />
		<ReferenceInput source="order_code" reference="orders">
			<AutocompleteInput optionText="code" optionValue="code" />
		</ReferenceInput>
	</Filter>
);
export default NotificationFilters;
