import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import React from "react";
import {
	Button,
	useNotify,
	useRefresh,
	useUnselectAll,
	useUpdateMany,
} from "react-admin";

const UnpayButton = ({ selectedIds }) => {
	const refresh = useRefresh();
	const notify = useNotify();
	const unselectAll = useUnselectAll();
	const [updateMany, { loading }] = useUpdateMany(
		"orders",
		selectedIds,
		{ paid: null },
		{
			onSuccess: () => {
				refresh();
				notify("Orders unpaid");
				unselectAll("orders");
			},
			onFailure: (error) => notify("Error: orders not updated", "warning"),
		}
	);

	return (
		<Button label="Unpay" disabled={loading} onClick={updateMany}>
			<MoneyOffIcon />
		</Button>
	);
};

export default UnpayButton;
