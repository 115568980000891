import RemoteErrorsInterceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import {
	Edit,
	NumberInput,
	required,
	SimpleForm,
	TextInput,
} from "react-admin";
import { compose } from "recompose";
import Title from "../components/Title";

const AttributeEdit = ({ dispatch, validate, errors, ...props }) => (
	<Edit title={<Title source="name" />} {...props} undoable={false}>
		<SimpleForm validate={validate}>
			<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
			<TextInput disabled source="id" />
			<TextInput validate={[required()]} source="name" />
			<TextInput validate={[required()]} source="code" />
			<NumberInput source="cost" />
		</SimpleForm>
	</Edit>
);
export default compose(withErrors)(AttributeEdit);
