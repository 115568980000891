let appUrl = `//${document.location.host}/`;
let environment = "PRODUCTION";
if (appUrl.endsWith(":3000/")) {
	appUrl = "https://platform.getmeyellow.com/";
	environment = "DEVELOPER";
}
export const APP_URL = appUrl;
export const API_URL = `${APP_URL}api`;
export const EMAIL_PREVIEW_URL = `${API_URL}/email-templates/preview/`;
export const VERSION = "1.0.1";
export const ENVIRONMENT = environment;
