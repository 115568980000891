import { Chip } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { get } from "lodash";
import moment from "moment";
import React from "react";

const PaidField = ({ record, source, ...props }) => {
	const paid = get(record, source, "Not paid yet");
	return (
		<Chip
			style={{
				backgroundColor: paid ? green[500] : red[500],
				color: "white",
			}}
			component="p"
			label={paid ? moment(paid).format("l h:mm") : "Not paid yet"}
			icon={paid ? <CheckIcon /> : <ClearIcon />}
		/>
	);
};

export default PaidField;
