import { CircularProgress } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import { get } from "lodash";
import React from "react";
import {
	MenuItemLink,
	useQuery,
	useRedirect,
	UserMenu as RaUserMenu,
} from "react-admin";
import authProvider from "./authProvider";

const useStyles = makeStyles(
	(theme) => ({
		root: {
			color: theme.palette.text.secondary,
		},
		icon: { minWidth: theme.spacing(5) },
	}),
	{ name: "RaMenuItemLink" }
);

const UserMenu = (props) => {
	const redirect = useRedirect();
	const classes = useStyles();
	const { data, loading } = useQuery({
		type: "getOne",
		resource: "users/profile",
		payload: {
			id: "useless",
		},
	});
	if (loading) return <CircularProgress size={20} color="inherit" />;
	const subToken = localStorage.getItem("sub_token");
	const handleSubLogout = (e) => {
		e.preventDefault();
		e.stopPropagation();
		authProvider("subLogout").then((success) => {
			redirect("/users");
			setTimeout(() => window.location.reload(), 200);
		});
	};
	return (
		<RaUserMenu label={get(data, "username")} {...props}>
			{data && (
				<>
					<MenuItemLink
						to={`/users/${get(data, "id", false)}`}
						primaryText="Profile"
						leftIcon={<SettingsIcon />}
					/>
					{subToken && (
						<MenuItem className={classes.root}>
							<ListItemIcon className={classes.icon} onClick={handleSubLogout}>
								<ExitToAppIcon />
							</ListItemIcon>
							<span onClick={handleSubLogout}>Sign out from subuser</span>
						</MenuItem>
					)}
				</>
			)}
		</RaUserMenu>
	);
};

export default UserMenu;
