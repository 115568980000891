import { makeStyles } from "@material-ui/core";
import React from "react";
import {
	BooleanField,
	ChipField,
	Datagrid,
	DateField,
	EditButton,
	List,
	NumberField,
	ReferenceField,
	ReferenceManyField,
	SingleFieldList,
	TextField,
} from "react-admin";
import AlertField from "../components/AlertField";
import ChipColorField from "../components/ChipColorField";
import Pagination from "../components/Pagination";
import perPage from "../utils/perPage";
import Aside from "./Aside";
import BulkActionButtons from "./BulkActionButtons";
import DownloadButton from "./DownloadButton";
import Filters from "./Filters";
import PaidField from "./PaidField";
import PrintedField from "./PrintedField";

const useStyles = makeStyles({
	list: {
		"& table td, table th": {
			whiteSpace: "nowrap",
		},
	},
});

const OrderList = ({ permissions, ...props }) => {
	const classes = useStyles();
	const hasRoles = (roles) => {
		if (!permissions) {
			return false;
		}
		var has = false;
		roles.forEach((r) => {
			if (permissions(r)) {
				has = true;
			}
		});
		return has;
	};
	return (
		<React.Fragment>
			<List
				{...props}
				exporter={false}
				perPage={perPage()}
				filters={<Filters permissions={permissions} />}
				sort={{ field: "created", order: "DESC" }}
				className={classes.list}
				bulkActionButtons={
					hasRoles(["admin", "object", "customer-service"]) && (
						<BulkActionButtons permissions={permissions} />
					)
				}
				aside={hasRoles(["admin", "designer", "customer-service"]) && <Aside />}
				pagination={<Pagination />}
			>
				<Datagrid rowClick="edit">
					<ChipField source="code" style={{ fontWeight: "bold" }} />
					{hasRoles(["admin", "designer", "customer-service"]) && (
						<ReferenceField
							source="status_id"
							reference="statuses"
							linkType={false}
						>
							<ChipColorField source="name" />
						</ReferenceField>
					)}
					{hasRoles(["admin", "designer", "customer-service"]) && (
						<AlertField source="status_alert" />
					)}
					{hasRoles(["admin", "customer-service"]) && (
						<ReferenceField source="user_id" reference="users" label="Designer">
							<TextField source="username" />
						</ReferenceField>
					)}
					{hasRoles(["admin", "customer-service", "object", "designer"]) && (
						<ReferenceManyField
							label="Formats"
							reference="items"
							target="order_id"
						>
							<SingleFieldList>
								<ChipField source="format" />
							</SingleFieldList>
						</ReferenceManyField>
					)}
					<NumberField source="items_count" sortable={false} />
					{hasRoles(["admin", "customer-service", "designer"]) && (
						<NumberField
							source="cost"
							locales="en-US"
							options={{ style: "currency", currency: "USD" }}
						/>
					)}
					{hasRoles(["admin", "customer-service"]) && (
						<DateField
							source="customer_expiration_date"
							showTime
							emptyText="Not assigned yet"
						/>
					)}
					{hasRoles(["admin", "customer-service", "designer"]) && (
						<DateField
							source="designer_expiration_date"
							showTime
							emptyText="Not assigned yet"
							label={
								permissions && permissions("admin")
									? "Designer expiration date"
									: "Expiration date"
							}
						/>
					)}
					{hasRoles(["admin", "customer-service"]) && (
						<ReferenceField
							label="Website"
							reference="websites"
							source="website_id"
						>
							<ChipField source="name" />
						</ReferenceField>
					)}
					{hasRoles(["admin", "customer-service", "designer"]) && (
						<PaidField source="paid" />
					)}
					{hasRoles(["admin", "customer-service", "object"]) && (
						<PrintedField source="printed" />
					)}
					{hasRoles(["admin", "object", "customer-service", "designer"]) && (
						<BooleanField source="fast_shipping" />
					)}
					<DateField source="created" showTime />
					<DownloadButton permissions={permissions} />
					{hasRoles(["admin", "customer-service", "designer"]) && (
						<EditButton />
					)}
				</Datagrid>
			</List>
		</React.Fragment>
	);
};
export default OrderList;
