import { makeStyles } from "@material-ui/core";
import RemoteErrorsInterceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import {
	AutocompleteInput,
	Edit,
	FormDataConsumer,
	FormTab,
	ReferenceInput,
	required,
	SelectInput,
	TabbedForm,
	TextInput,
} from "react-admin";
import { compose } from "recompose";
import CodeInput from "../components/CodeInput";
import Title from "../components/Title";
import { EMAIL_PREVIEW_URL } from "../config";
import { codes } from "./codes";
import Placeholders from "./Placeholders";

const useStyle = makeStyles({
	preview: {
		width: "100%",
		height: 600,
		border: "none",
	},
});

const EmailTemplateEdit = ({ dispatch, validate, errors, ...props }) => {
	const classes = useStyle();
	return (
		<Edit title={<Title source="name" />} {...props} undoable={false}>
			<TabbedForm validate={validate}>
				<FormTab label="Edit">
					<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
					<ReferenceInput
						validate={[required()]}
						source="website_id"
						reference="websites"
					>
						<SelectInput />
					</ReferenceInput>
					<TextInput validate={[required()]} source="name" />
					<SelectInput validate={[required()]} source="code" choices={codes} />
					<TextInput validate={[required()]} source="subject" fullWidth />
					<CodeInput validate={[required()]} source="body" fullWidth />
					<Placeholders />
				</FormTab>
				<FormTab label="Preview">
					<FormDataConsumer>
						{({ formData, ...rest }) => (
							<React.Fragment>
								<ReferenceInput source="order_id" reference="orders">
									<AutocompleteInput optionText="code" />
								</ReferenceInput>
								<iframe
									className={classes.preview}
									src={`${EMAIL_PREVIEW_URL}${formData.id}?order_id=${
										formData.order_id || null
									}&token=${localStorage.getItem("token")}`}
									title="preview"
								/>
							</React.Fragment>
						)}
					</FormDataConsumer>
				</FormTab>
			</TabbedForm>
		</Edit>
	);
};
export default compose(withErrors)(EmailTemplateEdit);
