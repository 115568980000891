import { makeStyles } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import CheckIcon from "@material-ui/icons/Check";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

const placeholders = [
	{ id: "<?= $order->id ?>", name: "Order ID" },
	{ id: "<?= $order->uuid ?>", name: "Order UUID" },
	{ id: "<?= $order->code ?>", name: "Order code" },
	{
		id: "<?= $order->customer_expiration_date ?>",
		name: "Customer expiration date",
	},
	{
		id: "<?= $order->designer_expiration_date ?>",
		name: "Designer expiration date",
	},
	{
		id: "<?= $order->website->$order->website->customer_expiration_days ?>",
		name: "Customer expiration days",
	},
	{
		id: "<?= $order->website->$order->website->customer_expiration_days ?>",
		name: "Order code",
	},
	{ id: "<?= $order->code ?>", name: "Order code" },
	{ id: "<?= $customer->first_name ?>", name: "Customer first name" },
	{ id: "<?= $customer->last_name ?>", name: "Customer last name" },
	{ id: "<?= $customer->email ?>", name: "Customer email" },
	{ id: "<?= $customer->phone ?>", name: "Customer phone" },
	{ id: "<?= $customer->address ?>", name: "Customer address" },
	{ id: "<?= $customer->zip_code ?>", name: "Customer zip code" },
	{ id: "<?= $customer->city ?>", name: "Customer city" },
	{ id: "<?= $customer->province ?>", name: "Customer province" },
	{ id: "<?= $customer->country ?>", name: "Customer country" },
	{ id: "<?= $customer->code ?>", name: "Customer code" },
	{ id: "<?= $designer->username ?>", name: "Designer username" },
	{ id: "<?= $message->message ?>", name: "Message" },
	{
		id:
			"<?= $this->element('Email/button', ['text' => 'text', 'href' => 'url']) ?>",
		name: "Button",
	},
];

const useStyle = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(1),
	},
	chip: {
		marginBottom: theme.spacing(1),
		marginRight: theme.spacing(1),
		backgroundColor: theme.palette.secondary,
	},
}));

const Placeholder = ({ id, name, style, delay = 3000 }) => {
	const [copied, setCopied] = useState(false);
	const handleCopy = () => {
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, delay);
	};
	return (
		<CopyToClipboard text={id} onCopy={handleCopy}>
			<Chip
				avatar={copied ? <CheckIcon /> : <FileCopyIcon />}
				className={style}
				label={`${copied ? "copied " : ""}${name}`}
			/>
		</CopyToClipboard>
	);
};

const Placeholders = () => {
	const classes = useStyle();
	return (
		<div className={classes.root}>
			{placeholders.map((placeholder, i) => (
				<Placeholder {...placeholder} style={classes.chip} key={i} />
			))}
		</div>
	);
};

export default Placeholders;
