import {
	FormControl,
	InputLabel,
	LinearProgress,
	makeStyles,
	MenuItem,
	Select,
	TextField,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconCancel from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState } from "react";
import {
	Button,
	useNotify,
	useQuery,
	useRefresh,
	useUnselectAll,
	useUpdateMany,
} from "react-admin";

const useStyles = makeStyles((theme) => ({
	formControl: {
		width: "100%",
	},
	textField: {
		"& label": {
			paddingLeft: theme.spacing(1),
		},
		"& input": {
			paddingLeft: theme.spacing(1),
		},
	},
}));

const EditAllDialog = ({ open, onClose, selectedIds, ...props }) => {
	const classes = useStyles();

	const [updateData, setUpdateData] = useState({});
	const refresh = useRefresh();
	const notify = useNotify();
	const unselectAll = useUnselectAll();
	const [updateMany, { loading }] = useUpdateMany(
		"orders",
		selectedIds,
		updateData,
		{
			onSuccess: () => {
				refresh();
				notify("Orders updated");
				unselectAll("orders");
			},
			onFailure: (error) => notify("Error: orders not updated", "warning"),
		}
	);
	const { data: statuses } = useQuery({
		type: "getList",
		resource: "statuses",
		payload: {
			pagination: { page: 1, limit: 200 },
			sort: { field: "id", direction: "ASC" },
		},
	});

	const { data: designers } = useQuery({
		type: "getList",
		resource: "users",
		payload: {
			pagination: { page: 1, limit: 200 },
			sort: { field: "username", direction: "ASC" },
			filter: {
				role: 2,
			},
		},
	});

	return (
		<Dialog fullWidth open={open} onClose={onClose}>
			<DialogTitle>Edit all</DialogTitle>
			<DialogContent>
				{!loading ? (
					<>
						<FormControl variant="filled" className={classes.formControl}>
							<InputLabel>Status</InputLabel>
							<Select
								value={updateData.status_id || ""}
								onChange={(e) => {
									if (e.target.value) {
										setUpdateData({
											...updateData,
											status_id: e.target.value,
										});
									} else {
										const { status_id, ...rest } = updateData;
										setUpdateData(rest);
									}
								}}
								margin="dense"
								fullWidth
							>
								<MenuItem value="">
									<em>None</em>
								</MenuItem>
								{statuses &&
									statuses.map((status) => (
										<MenuItem key={status.id} value={status.id}>
											{status.name}
										</MenuItem>
									))}
							</Select>
						</FormControl>
						<FormControl variant="filled" className={classes.formControl}>
							<Autocomplete
								style={{ marginTop: "16px" }}
								options={designers || []}
								getOptionLabel={(designer) => designer.username}
								onChange={(e, designer) =>
									setUpdateData({
										...updateData,
										user_id:
											designer && designer.id !== null ? designer.id : null,
									})
								}
								renderInput={(params) => (
									<TextField
										label="Designer"
										className={classes.textField}
										{...params}
										fullWidth
									/>
								)}
							/>
						</FormControl>
					</>
				) : (
					<LinearProgress />
				)}
			</DialogContent>
			{!loading && (
				<DialogActions>
					<Button label="Save" onClick={updateMany}>
						<EditIcon />
					</Button>
					<Button label="ra.action.cancel" onClick={onClose}>
						<IconCancel />
					</Button>
				</DialogActions>
			)}
		</Dialog>
	);
};

export default EditAllDialog;
