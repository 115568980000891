import React from "react";
import {
	ChipField,
	CloneButton,
	Datagrid,
	DateField,
	EditButton,
	List,
	ReferenceField,
	SelectField,
	TextField,
} from "react-admin";
import perPage from "../utils/perPage";
import { codes } from "./codes";
import Filters from "./Filters";

const EmailTemplateList = (props) => (
	<React.Fragment>
		<List {...props} exporter={false} perPage={perPage()} filters={<Filters />}>
			<Datagrid rowClick="edit">
				<TextField source="id" />
				<TextField source="name" style={{ fontWeight: "bold" }} />
				<SelectField source="code" choices={codes} />
				<TextField source="subject" />
				<ReferenceField source="website_id" reference="websites">
					<ChipField source="name" />
				</ReferenceField>
				<DateField source="created" showTime />
				<EditButton />
				<CloneButton />
			</Datagrid>
		</List>
	</React.Fragment>
);
export default EmailTemplateList;
