import { get } from "lodash";
import RemoteErrorsInterceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import {
	BooleanInput,
	CheckboxGroupInput,
	Edit,
	email,
	FormDataConsumer,
	ReferenceArrayInput,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
} from "react-admin";
import { compose } from "recompose";
import Title from "../components/Title";

const FormatMany = (many) => (many ? many.map((r) => r.id) : []);
const ParseMany = (many) => (many ? many.map((id) => ({ id })) : []);

const MyCheckboxGroupInput = ({ loaded, ...props }) => (
	<CheckboxGroupInput
		validate={required()}
		{...props}
		choices={props.choices || []}
	/>
);

const UserEdit = ({ dispatch, validate, errors, permissions, ...props }) => (
	<Edit title={<Title source="username" />} {...props} undoable={false}>
		<SimpleForm validate={validate}>
			<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
			{permissions && permissions("admin") && (
				<BooleanInput source="is_active" />
			)}
			{permissions && permissions("admin") && (
				<ReferenceInput source="website_id" reference="websites" allowEmpty>
					<SelectInput />
				</ReferenceInput>
			)}
			<TextInput validate={[required(), email()]} source="email" />
			<TextInput validate={required()} source="username" />
			<BooleanInput source="edit_password" />
			<FormDataConsumer>
				{({ formData, ...rest }) =>
					get(formData, "edit_password", false) === true ? (
						<TextInput
							validate={required()}
							source="password"
							type="password"
							{...rest}
						/>
					) : null
				}
			</FormDataConsumer>
			<TextInput
				validate={required()}
				source="profile.name"
				label="First name"
			/>
			<TextInput
				validate={required()}
				source="profile.surname"
				label="Last name"
			/>
			{permissions && permissions("admin") && (
				<BooleanInput source="profile.disable_email" label="Disable email" />
			)}
			<TextInput
				source="token"
				defaultValue={localStorage.getItem("token")}
				fullWidth
				disabled={true}
			/>
			{permissions && permissions("admin") && (
				<ReferenceArrayInput
					format={FormatMany}
					parse={ParseMany}
					source="roles"
					reference="roles"
					validate={required()}
					label="Roles"
				>
					<MyCheckboxGroupInput />
				</ReferenceArrayInput>
			)}
		</SimpleForm>
	</Edit>
);
export default compose(withErrors)(UserEdit);
