export default [
	{
		id: "shopify",
		name: "Shopify",
	},
	{
		id: "woocommerce",
		name: "Woocommerce",
	},
];
