import React from "react";
import { Datagrid, EditButton, List, TextField } from "react-admin";
import Filters from "./Filters";

const UserList = (props) => (
	<React.Fragment>
		<List {...props} exporter={false} perPage={25} filters={<Filters />}>
			<Datagrid rowClick="edit">
				<TextField source="id" />
				<TextField source="name" style={{ fontWeight: "bold" }} />
				<TextField source="code" />
				<EditButton />
			</Datagrid>
		</List>
	</React.Fragment>
);
export default UserList;
