import RemoteErrorsInterceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import { Edit, required, SimpleForm, TextInput } from "react-admin";
import { ColorInput } from "react-admin-color-input";
import { compose } from "recompose";
import Title from "../components/Title";

const StatusEdit = ({ dispatch, validate, errors, ...props }) => (
	<Edit title={<Title source="name" />} {...props} undoable={false}>
		<SimpleForm validate={validate}>
			<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
			<TextInput disabled source="id" />
			<TextInput validate={[required()]} source="name" />
			<TextInput validate={[required()]} source="code" />
			<TextInput multiline rows={5} source="description" />
			<ColorInput source="color" />
		</SimpleForm>
	</Edit>
);
export default compose(withErrors)(StatusEdit);
