import { createMuiTheme } from "@material-ui/core/styles";
export const useDarkMode = () =>
	window.matchMedia &&
	window.matchMedia("(prefers-color-scheme: dark)").matches;

export default createMuiTheme({
	palette: {
		type: useDarkMode() ? "dark" : "light",
		secondary: {
			main: "#FEC100",
		},
		primary: {
			main: "#0C5CA4",
		},
	},
	overrides: {},
});
