import React from "react";
import { Filter, ReferenceInput, SelectInput, TextInput } from "react-admin";
const EmailTemplateFilters = (props) => (
	<Filter {...props}>
		<TextInput source="q" label="Search..." alwaysOn />
		<ReferenceInput source="website_id" reference="websites">
			<SelectInput />
		</ReferenceInput>
	</Filter>
);
export default EmailTemplateFilters;
