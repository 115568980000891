import RemoteErrorsInterceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import {
	Create,
	FormTab,
	NumberInput,
	required,
	SelectInput,
	TabbedForm,
	TextInput,
} from "react-admin";
import { compose } from "recompose";
import languages from "./languages";
import platforms from "./platforms";
const WebsiteCreate = ({ dispatch, validate, errors, ...props }) => {
	return (
		<Create {...props}>
			<TabbedForm validate={validate}>
				<FormTab label="Website">
					<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
					<TextInput validate={[required()]} source="name" />
					<TextInput validate={[required()]} source="code" />
					<TextInput multiline source="description" />
					<TextInput fullWidth source="logo" />
					<TextInput fullWidth source="url" label="Website URL" />
					<SelectInput
						source="platform"
						choices={platforms}
						required={[required()]}
					/>
					<SelectInput
						source="language"
						choices={languages}
						required={[required()]}
					/>
					<TextInput fullWidth source="track_url" disabled />
					<NumberInput source="designer_expiration_days" />
					<NumberInput source="customer_expiration_days" />
					<TextInput
						fullWidth
						source="shipping_url"
						label="Shipping Postback URL"
					/>
				</FormTab>
				<FormTab label="SMTP">
					<TextInput source="smtp_host" label="Host" />
					<TextInput source="smtp_port" label="Port" defaultValue={465} />
					<TextInput source="smtp_username" label="Username" />
					<TextInput source="smtp_password" label="Password" type="password" />
					<TextInput source="smtp_from" label="From email address" />
					<TextInput source="smtp_from_name" label="From email name" />
					<TextInput
						source="smtp_delivery_from"
						label="From delivery email address"
					/>
					<TextInput
						source="smtp_delivery_from_name"
						label="From deliveery email name"
					/>
					<TextInput
						source="smtp_delivery_reply_to"
						label="Delivery Reply To"
					/>
				</FormTab>
			</TabbedForm>
		</Create>
	);
};
export default compose(withErrors)(WebsiteCreate);
